import { useEffect, useState, useCallback } from 'react';

import Background1 from 'resources/login-1.jpg';
import Background2 from 'resources/login-2.jpg';
import Background3 from 'resources/login-3.jpg';
import Background4 from 'resources/login-4.jpg';

import LogoImage from 'resources/prompto-logo-full-black.svg';

import styled, { StyledProps } from 'styled-components';
import InputField from 'components/InputField';
import Button from 'components/Button';

import { useHistory } from 'react-router-dom';

import { login } from 'api';

import { useAuthState } from 'stores/AuthStore';
import { fetchSettingsFromURL, getCurrentBackendEnvironment } from 'helpers';

const modalWidth = 450;
const modalHeight = 475;

const CenterModal = styled.div`
  position: absolute;
  left: calc(50% - ${modalWidth / 2.0}px);
  top: calc(50% - ${modalHeight / 2.0}px);
  width: ${modalWidth}px;
  min-height: ${modalHeight}px;
  border: 1px solid ${(props) => props.theme.gray20};
  border-radius: 5px;
  background-color: white;
  padding: 100px 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

const BackgroundWrapper = styled.div<StyledProps<any>>`
  position: absolute;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: url(${(props) => props.backgroundImage}) no-repeat;
  background-size: cover;
  filter: brightness(0.35);
`;

const Logo = styled.img`
  user-select: none;
  max-width: 332px;
  margin-bottom: 32px;
`;

const Indicator = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: green;
  margin-top: 16px;
  margin-right: 5px;
`;

const FirstP = styled.div`
  position: absolute;
  left: 100px;
  top: 125px;
  width: 41px;
  height: 55px;
`;

const SecondP = styled.div`
  position: absolute;
  left: 300px;
  top: 125px;
  width: 41px;
  height: 55px;
`;

const HorizontalWrapper = styled.div`
  display: flex;
  flex-flow: row;
`;

const StyledInput = styled(InputField)``;

const LoginPage = () => {
  const { AuthStateDispatch } = useAuthState();

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [passedFirstCheck, setPassedFirstCheck] = useState<boolean>(false);
  const [passedSecondCheck, setPassedSecondCheck] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [directLogin, setDirectLogin] = useState<boolean>(false);

  const [backgroundImage, setBackgroundImage] = useState<any>();

  const history = useHistory();

  const onTryLogin = useCallback(() => {
    if (username && password) {
      if (passedFirstCheck && passedSecondCheck) {
        /**
         * # 1 Check account the user tries to login with
         * Either it's the account of a specific user, or it's the admin account
         */
        setIsLoading(true);
        login(username, password)
          .then((result: any) => {
            const isAdmin = result?.data?.user?.objectId === 'ADMIN';
            let isUserPartOfPrompto = false;

            if (!isAdmin) {
              result?.data.user.vaultList.forEach((vault: any) => {
                if (
                  vault.objectId === getCurrentBackendEnvironment().promptoVault
                ) {
                  isUserPartOfPrompto = true;
                }
              });
            }

            if (isAdmin || isUserPartOfPrompto) {
              AuthStateDispatch({
                type: 'login',
                payload: {
                  user: result.data.user,
                  sessionToken: result.data.sessionToken,
                  isAdmin: isAdmin
                }
              });

              history.push(`/reporting${window.location.search}`);
            } else {
              failLogin();
            }
          })
          .catch((error: any) => {
            failLogin();
          });
      } else {
        failLogin();
      }
    }
  }, [
    AuthStateDispatch,
    history,
    passedFirstCheck,
    passedSecondCheck,
    password,
    username
  ]);

  const failLogin = () => {
    setPassedFirstCheck(false);
    setPassedSecondCheck(false);
    setIsLoading(false);
  };

  useEffect(() => {
    if (directLogin) {
      if (passedFirstCheck && passedSecondCheck) {
        if (username && password) {
          onTryLogin();
        }
      }
    }
  }, [
    username,
    password,
    passedFirstCheck,
    passedSecondCheck,
    directLogin,
    onTryLogin
  ]);

  useEffect(() => {
    const int = Math.floor(Math.random() * 4 + 1);

    switch (int) {
      default:
      case 1:
        setBackgroundImage(Background1);
        break;
      case 2:
        setBackgroundImage(Background2);
        break;
      case 3:
        setBackgroundImage(Background3);
        break;
      case 4:
        setBackgroundImage(Background4);
        break;
    }
  }, []);

  useEffect(() => {
    const params = fetchSettingsFromURL();

    if (params.username && params.password && !directLogin) {
      setPassedFirstCheck(true);
      setPassedSecondCheck(true);
      setDirectLogin(true);

      setUsername(params.username.toString());
      setPassword(params.password.toString());
    }
  }, [directLogin]);

  return (
    <>
      <BackgroundWrapper backgroundImage={backgroundImage} />
      <CenterModal>
        <FirstP
          onClick={() => {
            if (!passedFirstCheck && !passedSecondCheck) {
              setPassedFirstCheck(true);
            } else {
              failLogin();
            }
          }}
        />
        <SecondP
          onClick={() => {
            if (passedFirstCheck && !passedSecondCheck) {
              setPassedSecondCheck(true);
            } else {
              failLogin();
            }
          }}
        />
        <Logo src={LogoImage} alt="main_logo" />
        <StyledInput
          width={'300px'}
          label={'username'}
          onChange={(e) => {
            setUsername(e.target.value);
          }}
        />
        <StyledInput
          width={'300px'}
          label={'password'}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          type={'password'}
        />
        <Button
          disabled={isLoading}
          text={isLoading ? 'Processing' : 'Login'}
          onButtonClick={onTryLogin}
        />
        <HorizontalWrapper>
          {passedFirstCheck && <Indicator />}
          {passedSecondCheck && <Indicator />}
        </HorizontalWrapper>
      </CenterModal>
    </>
  );
};

export default LoginPage;
